import React, { useState } from "react"
import Button from "../Button"
import Loading from "../Loading"
import * as style from "./Header.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  getBackgroundImage,
  getGatsbyImage,
  isEmailValid,
  checkRequired,
  gaEventPush,
} from "../../plugins/common"
import { createReferral } from "../../DAO/eqtDAO"

const Header = ({ data, general, strapiId, token, lang }) => {
  const {
    title,
    image,
    backgroundImage,
    generateTitle,
    generateSubtitle,
    generateBtnLabel,
    emailLabel,
    emailPlaceholder,
    thankMessage,
  } = data

  const bg = getBackgroundImage(backgroundImage)
  const generalObj = general.allStrapiGeneral?.nodes[0]
  const { newsletter } = generalObj
  const { errorMessage, invalidEmailMessage } = newsletter

  const [email, setEmail] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const generateLink = async () => {
    if (!isEmailValid(email) || checkRequired(email)) {
      setErrorMsg(invalidEmailMessage)
      return
    }
    setErrorMsg("")
    setLoading(true)

    try {
      const body = {
        email: email,
        campaignId: strapiId,
        locale: lang,
      }
      const res = await createReferral(token, body)
      // console.log("res = ", res)

      gaEventPush(window, "event", "form submission", "lead", "referral")

      setSubmitted(true)
    } catch (error) {
      // console.log(error)
      setErrorMsg(errorMessage)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div
      className={`${style.header} position-relative d-lg-grid d-flex flex-column`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <h1 className={`ming align-self-center mx-lg-0 ${style.title}`}>
        {title}
      </h1>
      {image && (
        <div className={`${style.imageWrapper} position-relative`}>
          <GatsbyImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.image}  position-absolute`}
            image={getGatsbyImage(image)}
            alt="alea"
          />
        </div>
      )}
      <div
        className={`${style.form} ming bg-white soft-corners soft-shadows px-3 py-4 align-self-center me-lg-3 position-relative`}
      >
        {loading && <Loading />}
        <h2 className={`mb-1`}>{generateTitle}</h2>
        <p>{generateSubtitle}</p>
        {submitted ? (
          <div className={`teal openSans-Bold mt-4`}>{thankMessage}</div>
        ) : (
          <div>
            <div className={`d-grid mt-lg-4 mt-2 mb-3 ${style.inputWrapper}`}>
              <label
                className={`align-self-center me-4 ${
                  errorMsg ? "alert-red" : ""
                }`}
              >
                {emailLabel}
              </label>
              <input
                type="email"
                value={email}
                role="textbox"
                className={`border corners ${errorMsg ? `${style.error}` : ""}`}
                placeholder={emailPlaceholder}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            {errorMsg && (
              <div className={`alert-red my-2 openSans-Bold text-center`}>
                {errorMsg}
              </div>
            )}
            <div className={`text-lg-end text-center`}>
              <Button
                buttonId={`referral_hero_generate`}
                lang={lang}
                type={`primary`}
                text={generateBtnLabel}
                event={generateLink}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
