import React from "react"
import * as style from "./referral.module.scss"
import loadable from "@loadable/component"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getBackgroundImage,
  getCurrentLanguagePage,
} from "../plugins/common"
import Header from "../components/referral/Header"

const TitleContent = loadable(() =>
  import("../components/referral/TitleContent")
)
const Steps = loadable(() => import("../components/referral/Steps"))
const ItemBox = loadable(() => import("../components/homepageV2/ItemBox"))
const Partners = loadable(() => import("../components/vhis/Partners"))
const Reward = loadable(() => import("../components/referral/Reward"))
// import TitleContent from "../components/referral/TitleContent"
// import Steps from "../components/referral/Steps"
// import ItemBox from "../components/homepageV2/ItemBox"
// import Partners from "../components/vhis/Partners"
// import Reward from "../components/referral/Reward"

const ReferralPage = ({ pageContext, location }) => {
  const { data, general, form } = pageContext
  const {
    strapiId,
    header,
    intro,
    steps,
    rewardTable,
    whyChooseUs,
    partners,
    termsAndConditions,
  } = data
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(header.image)
  const aleaToken = pageContext.general.aleaToken
  // console.log("data: ", data)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  return (
    <Layout
      token={aleaToken}
      props={pageContext.general}
      meta={data.meta}
      localizations={getAllLocales(data.localizations, data.locale)}
      location={location}
      lang={lang}
      ogImage={ogImage}
      hideNewsletter={true}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <Header
        data={header}
        lang={lang}
        general={general}
        strapiId={strapiId}
        token={aleaToken}
      />
      <div className={`${style.referralBody} mt-5 mb-5 mx-auto`}>
        <TitleContent data={intro} />
        <Steps data={steps} />
        <Reward data={rewardTable} />
        <ItemBox lang={lang} data={whyChooseUs} full={true} />
        <Partners data={partners} lang={lang} />
        <TitleContent
          data={termsAndConditions}
          smallTitle={true}
          smallText={true}
        />
      </div>
    </Layout>
  )
}
export default ReferralPage
